<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div>
                <div class="">
                    <div class="card-body">
                        <div class="row" style="background-color: #F3FDFF;">
                            <div class="col-3 container">
                            <img 
                            src="resources/images/medisync-big.svg"
                            alt="Medisync"
                            height="50px"
                            >
                            </div>
                            <div class="col-6 header-text container">Daftar Pemeriksaan Dokter</div>
                            <div class="col-3 container">
                            {{ this.name }}
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <dashboard-dokter/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dashboardDokter from '@/components/tables/dashboard-dokter'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 5 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
    components: {
        dashboardDokter,
    },
    data() {
        return {
            name: null,
            labelCol,
            wrapperCol,
        }
    },
    created() {
        this.getName()
    },
    methods: {
        getName() {
            const name = localStorage.getItem('name')
            this.name = name
        },
    },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
.header-text{
    font-size: 18px;
    text-align: center;
    color: #428A94;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

</style>